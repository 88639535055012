import {Injectable, NgZone} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import URL from 'url-parse';
import {filter, map, take} from 'rxjs/operators';

import {MixpanelService} from '@shared/services/mixpanel';

import {AuthUser} from '../../services/auth-user';
import {Adapter, AdaptersNames, SchemaField, UserId} from '../../types';
import {RootDialogService} from '../../services/root-dialog.service';
import {Environment} from '../../services/environment';
import {isSimpleClick} from '../../utils/dom/is-simple-click';
import {RouterHelpers} from '../../services/router-helpers.service';
import {ProfileService} from '../../services/profile.service';
import {ThemeOverrides} from '../../theming/theme-types';
import {FieldQualifiedPath} from '../form-fields/field-helper.service';

import {OemAccountConfig, OemNavigationConfig, OemThemeConfig} from './oem.types';
import {
  EmbeddingErrorMessage,
  EmbeddingNavigationMessage,
  EmbeddingVendorMessage,
  EmbeddingWorkatoMessage,
  MarkedEmbeddingWorkatoMessage,
} from './embedding-messages';
import {EmbeddingUtils} from './embedding-utils';

const TEMPORARY_URL_REWRITE_TIMEOUT = 200;

export interface FieldOverride {
  path: FieldQualifiedPath;
  override: Partial<SchemaField>;
}

export interface AdapterOverrides {
  connectionFields: FieldOverride[];
}

export type AdapterOverridesMap = Record<Adapter['name'], AdapterOverrides>;

const ADAPTER_OVERRIDES_MAP: AdapterOverridesMap = {
  rest: {
    connectionFields: [
      {
        path: ['auth_type'],
        override: {
          label: 'Authentication type (overriden)',
          hint: 'Choose something here (overriden)',
        },
      },
      {
        path: ['url_param'],
        override: {
          label: 'Url parameters (overriden)',
        },
      },
      {
        path: ['url_param', 'param'],
        override: {
          label: 'Key (overriden)',
          hint: 'Overriden hint',
        },
      },
    ],
  },
};

@Injectable({
  providedIn: 'root',
})
export class OemService {
  themeEnabled = false;
  embedded = false;
  fullyEmbedded = false;
  embeddable = false;
  forbidExternalWorkatoLinks = false;

  private themeConfig?: OemThemeConfig;
  private accountConfig?: OemAccountConfig;
  private navigationConfig?: OemNavigationConfig;
  private currentHeight = 0;
  private embeddingUtils: EmbeddingUtils;
  private linkTransformTimeoutIds = new WeakMap<HTMLAnchorElement, number>();
  private emptyOnboarding: OemThemeConfig['onboarding'] = {title: '', button_text: '', image: '', paragraph: ''};

  constructor(
    private authUser: AuthUser,
    private dialog: RootDialogService,
    private profile: ProfileService,
    private env: Environment,
    private router: Router,
    private routerHelpers: RouterHelpers,
    private ngZone: NgZone,
    private mixpanelService: MixpanelService,
  ) {}

  get baseThemeKey(): OemThemeConfig['base_theme_key'] | undefined {
    return this.themeConfig?.base_theme_key;
  }

  get fontUrls(): string[] {
    return this.themeConfig?.font_urls ?? [];
  }

  get styles(): OemThemeConfig['styles'] {
    return this.themeConfig?.styles || {};
  }

  get mixedAssets(): OemThemeConfig['mixed_assets'] {
    return this.themeConfig?.mixed_assets;
  }

  get onboarding(): OemThemeConfig['onboarding'] {
    return this.themeConfig?.onboarding || this.emptyOnboarding;
  }

  get companyName(): OemThemeConfig['company_info']['name'] {
    return this.themeConfig?.company_info?.name ?? '';
  }

  get appName(): OemThemeConfig['company_info']['app_name'] {
    return this.themeConfig?.company_info?.app_name ?? '';
  }

  get appPairName(): OemThemeConfig['recommendation']['app_pair_name'] | undefined {
    return this.themeConfig?.recommendation?.app_pair_name;
  }

  get hasSearchAppPair(): boolean {
    return this.themeConfig?.recommendation?.search_app_pair ?? false;
  }

  get appLogo(): OemThemeConfig['company_info']['logo'] {
    return this.themeConfig?.company_info?.logo ?? '';
  }

  get appLogoSmall(): OemThemeConfig['company_info']['logo_small'] {
    return this.themeConfig?.company_info?.logo_small ?? '';
  }

  get appLogoGray(): OemThemeConfig['company_info']['logo_gray'] {
    return this.themeConfig?.company_info?.logo_gray ?? '';
  }

  get recommendedTag(): OemThemeConfig['recommendation']['tag'] {
    return this.themeConfig?.recommendation?.tag ?? '';
  }

  get recommendedUserId(): UserId | undefined {
    return this.themeConfig?.recommendation?.recipe_user_id;
  }

  get featuredApps(): AdaptersNames {
    return this.themeConfig?.recommendation?.apps ?? [];
  }

  get teamsDisableSaml(): OemThemeConfig['teams']['disable_saml'] {
    return this.themeConfig?.teams?.disable_saml ?? false;
  }

  get teamsDisableCollaboratorInvitations(): OemThemeConfig['teams']['disable_collaborator_invitations'] {
    return this.themeConfig?.teams?.disable_collaborator_invitations ?? false;
  }

  get isWhitelabel(): boolean {
    return this.themeConfig?.type === 'whitelabel';
  }

  get isOnEmbeddingErrorPage(): boolean {
    return this.routerHelpers.isCurrentlyOnPage('directLinkError');
  }

  get isEmbeddedWidget(): boolean {
    return this.embedded && !this.fullyEmbedded;
  }

  get hasWelcomeDialog(): boolean {
    const isFirstTime = this.authUser.oem_user && !this.authUser.oem_welcome_shown && this.authUser.team_first_time;

    return isFirstTime && this.isWhitelabel;
  }

  get footerLinks(): OemThemeConfig['footer'] {
    return this.themeConfig?.footer;
  }

  get workspaceSwitchingDisabled(): boolean {
    return Boolean(this.accountConfig?.team_switching_disabled);
  }

  get communityNavItemHidden(): boolean {
    return Boolean(!this.authUser.oem_admin && this.navigationConfig?.community_library?.hidden);
  }

  get communityConnectorsHidden(): boolean {
    return Boolean(!this.authUser.oem_admin && this.themeConfig?.recommendation?.disable_custom_connectors);
  }

  get communityRecipesExcluded(): boolean {
    return Boolean(!this.authUser.oem_admin && this.themeConfig?.recommendation?.exclude_community_recipes);
  }

  get footerBrandingVisible(): boolean {
    return !this.navigationConfig?.footer_branding?.hidden;
  }

  initialize() {
    this.themeConfig = this.authUser.oem_config?.theme;
    this.accountConfig = this.authUser.oem_config?.account;
    this.navigationConfig = this.authUser.oem_config?.navigation;
    this.themeEnabled = Boolean(this.themeConfig);
    this.embeddable = Boolean(this.themeConfig?.embedding);
    this.embedded = this.embeddable && this.env.inIframe;
    this.fullyEmbedded = Boolean(this.embedded && this.themeConfig!.embedding!.full_embedding);
    this.forbidExternalWorkatoLinks = Boolean(
      this.fullyEmbedded && this.themeConfig!.embedding!.forbid_external_workato_links,
    );

    this.mixpanelService.peopleSet({
      oem_user: this.authUser.oem_user,
      embedded: this.embedded,
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        this.setupEmbedding();
      });
  }

  setupEmbedding() {
    if (this.isOnEmbeddingErrorPage) {
      return;
    }

    if (this.embedded) {
      this.initializeEmbedding();

      if (this.fullyEmbedded) {
        this.initializeFullEmbedding();
      }
    } else {
      this.showWelcomeDialog();
    }
  }

  updateCurrentBaseTheme(baseTheme: OemThemeConfig['base_theme_key']) {
    if (this.themeConfig) {
      this.themeConfig.base_theme_key = baseTheme;
    }
  }

  getOverridesForTheme(themeKey: OemThemeConfig['base_theme_key']): ThemeOverrides {
    return !this.baseThemeKey || this.baseThemeKey === themeKey ? this.styles : {};
  }

  updateStyles(overrides: ThemeOverrides) {
    if (this.themeConfig) {
      this.themeConfig.styles = overrides;
    }
  }

  sendEmbeddingMessage(message: EmbeddingWorkatoMessage) {
    if (this.embedded && this.themeConfig?.embedding) {
      window.parent.postMessage(this.constructPostMessage(message), this.themeConfig.embedding.origin);
    }
  }

  sendFatalEmbeddingErrorMessage(reason: string) {
    if (this.env.inIframe) {
      const message: EmbeddingErrorMessage = {
        type: 'error',
        payload: {
          type: 'FatalEmbeddingError',
          message: `Fatal embedding error: ${reason}`,
          details: {reason},
        },
      };

      window.parent.postMessage(this.constructPostMessage(message), '*');
    }
  }

  getOverridesForAdapter(name: Adapter['name']): AdapterOverrides | null {
    return ADAPTER_OVERRIDES_MAP[name] ?? null;
  }

  async showWelcomeDialog() {
    if (this.hasWelcomeDialog) {
      const {OemWelcomeDialogComponent} = await import('./oem-welcome-dialog/oem-welcome-dialog.component');

      this.dialog
        .openFullscreen(OemWelcomeDialogComponent, {
          class: 'fullscreen-dialog_center',
          style: {
            backgroundColor: this.styles['backdrop-landing'] ?? '#fff',
          },
          cancellable: false,
          width: 685,
          contentInputs: {
            data: this.onboarding,
          },
        })
        .closed.subscribe(async () => {
          try {
            await this.profile.updateMembershipProperties({oem_welcome_shown: true});
            this.authUser.oem_welcome_shown = true;
          } catch (err) {
            // Ignore error
          }
        });
    }
  }

  handleHeightChange(fullHeight = document.body.scrollHeight) {
    const height = Math.max(
      fullHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight,
    );

    if (height !== this.currentHeight) {
      this.currentHeight = height;

      this.sendEmbeddingMessage({
        type: 'heightChange',
        payload: {
          height: this.currentHeight,
        },
      });
    }
  }

  private initializeEmbedding() {
    this.handleHeightChange();
  }

  private initializeFullEmbedding() {
    const embeddingConfig = this.themeConfig!.embedding!;

    this.embeddingUtils = new EmbeddingUtils({
      workatoOrigin: location.origin,
      vendorOrigin: embeddingConfig.origin,
      pathPrefix: embeddingConfig.path_prefix,
    });

    this.router.events
      .pipe(
        filter(e => e instanceof RouterEvent),
        // workaround for wrong Angular typing https://github.com/angular/angular/issues/43124
        map(e => e as RouterEvent),
      )
      .subscribe(this.handleRouterEvents);
    window.addEventListener('message', this.handleMessageEvent);
    window.addEventListener('beforeunload', this.handleBeforeUnloadEvent);

    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('mouseenter', this.handleMouseenterEvents, true);
      document.addEventListener('focus', this.handleFocusEvents, true);
      document.addEventListener('click', this.handleClickEvents, true);
    });

    this.sendEmbeddingMessage({
      type: 'loaded',
      payload: {
        url: location.pathname + location.search + location.hash,
      },
    });
  }

  private async handleVendorNavigationMessage(payload: any) {
    if (!payload || typeof payload.url !== 'string') {
      return;
    }

    let {url} = payload as NonNullable<EmbeddingNavigationMessage['payload']>;
    let parsedUrl: URL<string>;

    try {
      parsedUrl = new URL(url.trim());
    } catch (err) {
      return;
    }

    if (parsedUrl.origin !== location.origin) {
      return;
    }

    url = parsedUrl.pathname + parsedUrl.query + parsedUrl.hash;

    const currentUrl = this.router.navigated ? this.router.url : location.pathname + location.search + location.hash;

    if (currentUrl === url) {
      return;
    }

    if (this.router.navigated) {
      try {
        await this.router.navigateByUrl(url);
      } catch (err) {
        location.href = url;
      }
    } else {
      location.href = url;
    }
  }

  private rewriteHrefToVendorUrl(link: HTMLAnchorElement) {
    if (!link.hasAttribute('href')) {
      return;
    }

    const vendorUrl = this.embeddingUtils.getEmbeddingUrl(link.href, true);

    if (vendorUrl) {
      link.href = vendorUrl;
    }
  }

  private temporaryRewriteLinkToWorkatoUrl(link: HTMLAnchorElement) {
    const timeoutIds = this.linkTransformTimeoutIds;
    const workatoUrl = this.embeddingUtils.getWorkatoUrl(link.href);

    if (workatoUrl) {
      if (timeoutIds.has(link)) {
        clearTimeout(timeoutIds.get(link));
      }

      const vendorUrl = link.href;

      link.href = workatoUrl;
      timeoutIds.set(
        link,
        setTimeout(() => {
          link.href = vendorUrl;
          timeoutIds.delete(link);
        }, TEMPORARY_URL_REWRITE_TIMEOUT),
      );
    }
  }

  private processLink(link: HTMLAnchorElement) {
    if (this.forbidExternalWorkatoLinks && link.target && link.target !== '_self' && link.origin === location.origin) {
      link.removeAttribute('target');
    } else if (
      (!link.target || link.target === '_self') &&
      link.origin !== location.origin &&
      link.origin !== this.themeConfig?.embedding?.origin
    ) {
      // All links with external origin should be opened in new tab
      link.setAttribute('target', '_blank');
    }
  }

  private constructPostMessage(message: EmbeddingWorkatoMessage): string {
    const markedMessage: MarkedEmbeddingWorkatoMessage = {
      wk: true,
      ...message,
    };

    return JSON.stringify(markedMessage);
  }

  private isValidMessage(message: unknown): message is EmbeddingVendorMessage {
    return Boolean(message && typeof (message as EmbeddingVendorMessage).type === 'string');
  }

  private handleBeforeUnloadEvent = () => {
    this.sendEmbeddingMessage({type: 'unloaded'});
  };

  private handleMessageEvent = (event: MessageEvent) => {
    if (event.origin !== this.themeConfig?.embedding?.origin) {
      return;
    }

    let message = event.data;

    if (typeof message !== 'string') {
      return;
    }

    try {
      message = JSON.parse(message);
    } catch (err) {
      return;
    }

    if (!this.isValidMessage(message)) {
      return;
    }

    const {type, payload} = message;

    switch (type) {
      case 'navigation':
        this.handleVendorNavigationMessage(payload);
        break;
    }
  };

  private handleRouterEvents = (event: RouterEvent) => {
    if (event instanceof NavigationEnd) {
      const navigationExtras = this.router.getCurrentNavigation()?.extras;

      if (!this.isOnEmbeddingErrorPage) {
        this.sendEmbeddingMessage({
          type: 'navigated',
          payload: {
            url: event.urlAfterRedirects,
            replaced: Boolean(navigationExtras?.replaceUrl),
          },
        });
      }
    }
  };

  private handleMouseenterEvents = (event: MouseEvent) => {
    if ((event.target as Node).nodeType !== 1) {
      return;
    }

    const link = (event.target as HTMLElement).closest('a');

    if (link) {
      this.processLink(link);
      this.rewriteHrefToVendorUrl(link);
    }
  };

  private handleFocusEvents = (event: FocusEvent) => {
    const node = event.target as Node;

    // Handling only focus events on links
    if (node.nodeType !== 1 || (node as Element).tagName !== 'A') {
      return;
    }

    this.processLink(node as HTMLAnchorElement);
    this.rewriteHrefToVendorUrl(node as HTMLAnchorElement);
  };

  private handleClickEvents = (event: MouseEvent) => {
    /*
     * Sending click events because browsers don't emit "click" events over iframes.
     * This event may help in situations when you need to close popup with outside-click and user clicks somewhere
     * within the iframe.
     */
    this.sendEmbeddingMessage({type: 'click'});

    /*
     * Temporary change embedding URL to the original Workato URL on regular clicks.
     * Otherwise Angular router won't handle.
     */
    if ((event.target as Node).nodeType !== 1 || !isSimpleClick(event)) {
      return;
    }

    const link = (event.target as HTMLElement).closest('a');

    // Handling only clicks on links
    if (!link) {
      return;
    }

    const target = link.getAttribute('target');

    // Handling only navigations in the current tab/window
    if (target && target !== '_self') {
      return;
    }

    this.temporaryRewriteLinkToWorkatoUrl(link);
  };
}
