import {Injectable} from '@angular/core';

import {pluralize} from '@shared/pipes/plural.pipe';

import {Genie, Knowledge, PartialGenie} from '../types';
import {isGenieActive} from '../modules/recipe-editor/recipe.utils';

import {Validation, ValidationRule} from './validation';
import {GENIE_NAME_MAX_LENGTH, GENIE_NAME_REGEXP, GENIE_NAME_VALIDATION_MESSAGE} from './genie.constants';

@Injectable({
  providedIn: 'root',
})
export class GenieHelper {
  maxLengthValidator: ValidationRule;
  regexpValidator: ValidationRule;

  constructor(private validation: Validation) {
    this.maxLengthValidator = this.validation.maxLengthValidator(GENIE_NAME_MAX_LENGTH);
    this.regexpValidator = this.validation.regexpValidator(GENIE_NAME_REGEXP);
    this.regexpValidator.message = GENIE_NAME_VALIDATION_MESSAGE;
  }

  validateName(name: string): string | null {
    const errors = this.validation.validate({name}, {name: [this.maxLengthValidator, this.regexpValidator]});

    if (errors.name) {
      return GENIE_NAME_VALIDATION_MESSAGE;
    }

    return null;
  }

  getDeleteGenieDisabledDescription(genie: Genie): string | null {
    if (isGenieActive(genie)) {
      return 'You can’t delete an active genie. Stop the genie first.';
    }

    if (genie.active_recipes_count) {
      return 'It has active skill or knowledge recipes. Stop them first.';
    }

    return null;
  }

  getDeleteKnowledgeDisabledDescription(knowledge: Knowledge, genie: Genie): string | null {
    if (isGenieActive(genie)) {
      return this.getDisabledActiveGenieDescription('delete a knowledge base');
    }

    if (knowledge.active_recipes_count) {
      return 'It has active knowledge recipes. Stop them first.';
    }

    return null;
  }

  getDeleteSkillDisabledDescription(genie?: Pick<Genie, 'state'>): string | null {
    return isGenieActive(genie) ? this.getDisabledActiveGenieDescription('delete a skill') : null;
  }

  getStartStopSkillDisabledDescription(genie?: Pick<Genie, 'state'>): string | null {
    return isGenieActive(genie) ? this.getDisabledActiveGenieDescription('start or stop a skill') : null;
  }

  getDeleteConnectionDisabledDescription(genie?: Pick<Genie, 'state'>): string | null {
    return isGenieActive(genie) ? this.getDisabledActiveGenieDescription('delete a connection') : null;
  }

  getCreateKnowledgeDisabledDescription(genie?: Pick<Genie, 'state'>): string | null {
    return isGenieActive(genie) ? this.getDisabledActiveGenieDescription('create a knowledge base') : null;
  }

  getStartGenieDisabledDescription(genie: Genie, isConnectedToChatInterface: boolean): string | null {
    if (!genie.saml_enabled) {
      return 'Setup your SAML based SSO in <b>User access</b> before you can start any genie';
    }

    if (!genie.connection_id || !isConnectedToChatInterface) {
      return 'Connect the chat interface in <b>Basic setup</b> before starting this genie';
    }

    if (!genie.user_groups.length) {
      return 'Grant access to at least one user group in this genie’s <b>Access</b> before starting it';
    }

    return null;
  }

  getDeleteUserGroupDisabledDescription(userGroupGenies: PartialGenie[]): string | null {
    const activeGeniesCount = userGroupGenies.filter(genie => isGenieActive(genie)).length;

    return activeGeniesCount
      ? `You can’t delete a user group when it’s assigned to ${pluralize(activeGeniesCount, 'active genie', true)}. Stop the ${pluralize(activeGeniesCount, 'genie', true)} first.`
      : null;
  }

  private getDisabledActiveGenieDescription(action: string): string {
    return `You can’t ${action} when the genie is active. Stop the genie first.`;
  }
}
